/* th.header-match {
    color: #a1a5b7 !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    background-color: rgb(245, 248, 250) !important;
    width: 1% !important;
    white-space: nowrap !important;
    padding: 16px 32px !important;
} */
td.body-match, th.header-match {
    width: 1% !important;
    white-space: nowrap !important;
    padding: 16px 32px !important;
}
.padding-start-match {
    padding-left: 32px !important;
}
.padding-end-match {
    padding-right: 32px !important;
}
.padding-x-match {
    padding-right: 32px !important;
    padding-left: 32px !important;
}
.svg-icon.dark-icon path:nth-child(2) {
    fill: #181c32;
    opacity: 0.8;
}
.aside-menu .menu-item .menu-icon {
    min-width: 35px;
}
.aside-menu .menu-item .menu-icon .svg-icon {
    margin-left: auto;
    margin-right: auto;
}
